exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-enviado-error-h-101-js": () => import("./../../../src/pages/enviado-error-h101.js" /* webpackChunkName: "component---src-pages-enviado-error-h-101-js" */),
  "component---src-pages-enviado-success-js": () => import("./../../../src/pages/enviado-success.js" /* webpackChunkName: "component---src-pages-enviado-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-reservas-grupos-js": () => import("./../../../src/pages/reservas-grupos.js" /* webpackChunkName: "component---src-pages-reservas-grupos-js" */),
  "component---src-pages-restaurantes-js": () => import("./../../../src/pages/restaurantes.js" /* webpackChunkName: "component---src-pages-restaurantes-js" */),
  "component---src-templates-restaurante-js": () => import("./../../../src/templates/restaurante.js" /* webpackChunkName: "component---src-templates-restaurante-js" */)
}

